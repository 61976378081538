/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Subtitle, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1of9d5s --parallax pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"[[T00Text01]]"}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<br>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":972,"marginTop":null}} content={"Žaluzie - Horizontální, vertikální<br><br>Sítě proti hmyzu - okenní, dveřní, plisované<br><br>značky ISOTRA<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1of9d5s --parallax pb--60 pt--60" name={"azl30v7990m"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/35384e62b2cd4c46af9b6c729c27c60a_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--15);\">ŽALIZIE značky ISOTRA</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--15);\">Horizontální žaluzie</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--26" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold; color: var(--color-custom-1--15);\">V současné době již nestojí otázka, zda žaluzie ano či ne, ale v jakém materiálu a barvě. <br>Horizontální žaluzie jsou nejběžnější způsob zastínění interiéru. <br><br>Nejběžnější ale neznamená obyčejný. Máte zájem o lamely z hliníku mnoha barev, nebo dáte radši přednost přírodnímu dřevu? <br><br>Vyberte si vhodný typ přesně do vašeho interiéru, žaluzie ho vždy vhodně doplní.</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1--15);\">Využijte nevšedního umístění žaluzií</span>"}>
              </Subtitle>

              <Text className="text-box fs--26" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1--15);\">Dávno jsou pryč doby, kdy se žaluzie montovaly pouze na okenní rám nebo mezi okenní tabule. Dnes můžete využít atraktivního umístění žaluzií na stěnu nebo do stropu. Vytvoříte díky nim zástěnu, za kterou schováte rám okna, okenní kliku i parapet. Interiér se tak sjednotí a působí minimalisticky.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}